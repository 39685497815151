
@font-face {
    font-family: 'Carattere';
    src: url('./../../FuentesTipograficas/Carattere-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Pridi';
    src: url('./../../FuentesTipograficas/Pridi-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    }

